<template>

  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ตั้งค่ากลุ่มผู้ใช้งาน</div>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="user_group_id=0,dialogUpdate=true"><v-icon left small>mdi-plus</v-icon> เพิ่มกลุ่มผู้ใช้งาน</v-btn>
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"

      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer

      item-key="_id"
      @click:row="edit"
    >

      <!-- <template v-slot:[`item.status`]="{ item }">
        <menu-ship small v-model="item.status"/>
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom :page="filter.page" :itemsPerPage="filter.item_per_page" :length="pageCount" @change-page="onChangePage" @change-items-per-page="onChangeitemsPerPage"/>
      </template>

    </v-data-table>

    <v-dialog persistent scrollable max-width="90%" v-model="dialogUpdate">
      <Detail :id="user_group_id" @onClose="dialogUpdate=false" @success="getData"/>
    </v-dialog>

  </div>

</template>

<script>
  import Detail from '@/views/pages/setting/user-group/Detail.vue';


  export default {
    components: {
      Detail,
    },
    data: () => ({
      search: '',
      loading: true,
      pageCount: 0,
      headers: [
        { text: 'กลุ่มผู้ใช้', value: 'group_name', align: 'center'},
        { text: '', value: 'actions', sortable: false, align: 'center', width: '20%'},
      ],
      list: [],
      dialogUpdate: false,
      filter: {
        item_per_page: 20,
        page: 1,
        start_with: "",
      },
      user_group_id: 0
    }),

    created () {
      this.getData()
    },

    methods: {
      edit(item){
        this.user_group_id=item._id
        this.dialogUpdate=true
      },
      remove(item) {
        this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
          async (result) => {
            if (result.isConfirmed) {
              let body = {
                token: this.$jwt.sign({ user_group_id : item._id}, this.$privateKey, { noTimestamp: true }),
              };
              
              await this.$axios.post(`${this.$baseUrl}/user_group/delete_user_group`, body)
                .then(res=>{
                  this.$alertSuccess({ title : res.status });
                  this.getData();
                })
                .catch((err) => {
                  this.$alertServerError({ title : err.error_message });
                })
              console.log("remove()");
            }
          }
        );
      },
      async getData () {
        let body = {
          token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/user_group/get_user_group`, body)
        .then(res=>{
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        })
        this.loading = false;
      },
      onChangePage(page){
        this.filter.page = page
        this.getData()
      },
      onChangeitemsPerPage(number){
        this.filter.item_per_page = number
        this.onChangePage(1)
      },
    }
  }
</script>